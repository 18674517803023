<template>
  <div class="book_view">
    <BreadCrumb :items="breadCrumb">
      <template v-slot:controls>
        <button class="btn btn-sm btn-success" @click="$router.push({name: 'bke-book-new'})" title="Add a new book"><AddNewIcon /> Add New Book</button>
      </template>
    </BreadCrumb>
    <div class="row">
      <div class="col">
        <BookView :book="bookAsArray" :busy="bookLoading">
          <template v-slot:controls>
            <button class="btn btn-sm btn-outline-success" :disabled="!book" @click="$router.push({name:'quiz-list', params:{book_id}})" title="Quizzes related to this book">View Quiz</button>
            <button class="btn btn-sm btn-primary ml-2" :disabled="!book" @click="$router.push({name:'bke-book-edit', params:{book_id}})" title="Edit this book">Edit</button>
            <button class="btn btn-sm btn-danger ml-2" :disabled="!book" @click="deleteBook" title="Delete this book">Delete</button>
          </template>
        </BookView>
      </div>
    </div>
  </div>
</template>

<script>
import AddNewIcon from 'mdi-vue/PlaylistPlus';
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import BookView from '@/views/public/book/components/BookView.vue';
import getBookMixin from '@/mixins/getbook';

export default {
  name: 'BackendViewBook',

  mixins: [getBookMixin],
  components: { BreadCrumb, AddNewIcon, BookView },

  data() {
    return {
      book_id: this.$route.params.book_id,
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books List', to: { name: 'bke-book-list' } },
        { text: this.book_id || 'View', active: true },
      ];
    },
  },

  methods: {
    deleteBook() {
      this.$bvModal.msgBoxConfirm('This book will be deleted. Are you sure?', {
        buttonSize: 'sm', centered: true, okVariant: 'danger', okTitle: 'Yes', cancelTitle: 'No',
      })
        .then((reply) => {
          if (reply === true) {
            this.bookLoading = true;
            this.$store.dispatch('book/deleteBooks', [this.book_id])
              .then(() => {
                this.$router.push({ name: 'bke-book-list' });
              }).catch(() => {
                this.$toast.error('Failed to delete selected book. Try again later.');
              }).finally(() => {
                this.bookLoading = false;
              });
          }
        })
        .catch(() => { /* ignore */ });
    },
  },

};
</script>
