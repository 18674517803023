import { render, staticRenderFns } from "./BookView.vue?vue&type=template&id=b8218784&scoped=true&"
import script from "./BookView.vue?vue&type=script&lang=js&"
export * from "./BookView.vue?vue&type=script&lang=js&"
import style0 from "./BookView.vue?vue&type=style&index=0&id=b8218784&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8218784",
  null
  
)

export default component.exports