<template>
  <ol class="list-unstyled d-flex flex-wrap">
    <li class="breadcrumb-item" v-for="(item, i) in items" :key="i" :class="{active: item.active}">
      <b-link :to="item.to" target="_self" :aria-current="(item.active ? 'location' : null)" v-if="item.to">{{item.text}}</b-link>
      <span :aria-current="(item.active ? 'location' : null)" v-else>{{item.text}}</span>
    </li>
    <li class="ml-auto">
      <slot name="controls" />
    </li>
  </ol>
</template>

<script>
export default {
  name: 'BreadCrumb',
  props: {
    items: {
      type: Array,
      default() { return []; },
    },
  },
};
</script>
