<template>
  <b-card class="book_preview" header-class="text-right" footer-class="text-right">
    <template v-slot:header>
      <slot name="controls">
        <span />
      </slot>
    </template>
    <template v-slot:footer>
      <slot name="controls">
        <span />
      </slot>
    </template>

    <b-table stacked :items="book" :busy="busy" :fields="fields" show-empty>
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-2" />
          <span>Loading...</span>
        </div>
      </template>
      <template v-slot:empty>
        <h4>Specified book not found.</h4>
      </template>
      <template v-slot:cell(coverimage)="cell">
        <div class="cover row">
          <div class="img_div" v-for="(v, i) in cell.value" :key="i">
            <b-img :src="v" />
          </div>
        </div>
      </template>
      <template v-slot:cell(author)="cell">
        <ul><li v-for="(v, i) in cell.value" :key="i">{{v}}</li></ul>
      </template>
      <template v-slot:cell(publisher)="cell">
        <ul><li v-for="(v, i) in cell.value" :key="i">{{v}}</li></ul>
      </template>
      <template v-slot:cell(content_path)="cell">
        <ul>
          <li v-for="(v, i) in cell.value" :key="i">
            <b-link :href="v" target="_blank" rel="noopener noreferrer">{{v}}</b-link>
          </li>
        </ul>
      </template>
    </b-table>

  </b-card>
</template>

<script>

export default {
  name: 'BookView',
  props: {
    book: { type: [Array, Function], required: true },
    busy: { type: Boolean, default: false },
  },

  data() {
    return {
      fields: [
        { key: 'isbn_13', label: 'ISBN 13' },
        { key: 'isbn_10', label: 'ISBN 10' },
        { key: 'title' },
        { key: 'subtitle' },
        { key: 'coverimage' },
        { key: 'abstract' },
        { key: 'author' },
        { key: 'publisher' },
        { key: 'published_year', label: 'Published Year' },
        { key: 'edition' },
        { key: 'language_locale', label: 'Language Locale' },
        { key: 'content_path', label: 'Content URL' },
        { key: 'rating' },
        { key: 'min_grade', label: 'Min. School Grade' },
        { key: 'max_grade', label: 'Max. School Grade' },
      ],
    };
  },

};
</script>

<style lang="stylus" scoped>

>>> .b-table-stacked
  td:first-child
    border 0
    padding-top 0
  ul
    margin-bottom 0
  .img_div
    position relative
    width 5rem
    padding 0 0.3rem 0.5rem 0.3rem
    img
      width 100%

</style>
