export default {
  data() {
    return {
      book_id: '',
      book: null,
      bookLoading: false,
    };
  },

  computed: {
    bookAsArray() {
      return this.book ? [this.book] : [];
    },
  },

  methods: {
    getBook() {
      if (this.book_id && !this.book) {
        this.bookLoading = true;
        this.$store.dispatch('book/getBookById', this.book_id).then((book) => {
          this.book = book;
        }).catch(() => {}).finally(() => {
          this.bookLoading = false;
        });
      }
    },
  },

  created() {
    this.$nextTick(() => { this.getBook(); });
  },
};
